import React, { FC, ReactElement, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { Box, debounce, Grid, makeStyles, Paper } from '@material-ui/core'
import { Header } from './components/header'
import { TableList } from './components/table-list'
import { Footer } from './components/footer'
import { httpService } from 'core/data'
import { GridColumn, RowSelectionChange } from './types'

type GridViewType = 'list' | 'cards'

type QueryOptions = {
  enabled: boolean
}

type ApiCollectionResponse = {
  'hydra:member': Record<string, any>[]
  'hydra:totalItems': number
}

const useStyles = makeStyles(() => ({
  colum: {
    maxHeight: 'inherit',
  },
  paper: {
    maxHeight: 'inherit',
    height: '100%',
  },
}))

type Props = {
  resource: string
  columns: GridColumn[]
  cardComponent?: ReactElement
  defaultViewType?: GridViewType
  leftSidebar?: ReactNode
  rightSidebar?: ReactNode
  filters?: Record<string, any>
  queryOptions?: Partial<QueryOptions>
  selected: number[]
  onRowSelect?: (value: any) => void
  onRowSelectionChange?: RowSelectionChange
  onDoubleClickSelect?: (value: any) => void
  actionsHolder?: ReactNode
  queryKey?: string
  maxHeight: string
  breadcrumbs?: ReactNode
  onSearchChange?: (val: string) => void
}

export const DataGrid: FC<Props> = (props) => {
  const {
    resource,
    columns,
    cardComponent,
    filters,
    leftSidebar,
    rightSidebar,
    queryOptions = {},
    selected,
    onRowSelectionChange,
    onDoubleClickSelect,
    actionsHolder,
    queryKey,
    maxHeight,
    breadcrumbs,
    onSearchChange,
  } = props

  const classes = useStyles()

  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(20)
  const [totalItems, setTotalItems] = useState(0)
  const [search, setSearch] = useState('')

  const [debouncedSearch, setDebouncedSearch] = useState('')

  const setDebouncedSearchHandler = useRef(debounce(setDebouncedSearch, 300))

  const searchHandler = useCallback(
    (value: string) => {
      setDebouncedSearchHandler.current(value)
      setSearch(value)
      onSearchChange?.(value)
    },
    [onSearchChange]
  )

  useEffect(() => {
    setPage(1)
  }, [filters, perPage, debouncedSearch])

  const { data, isLoading, isFetching } = useQuery(
    [queryKey, 'data-grid', resource, page, perPage, debouncedSearch, filters],
    () => {
      const params = {
        page,
        itemsPerPage: perPage,
        search: debouncedSearch,
        ...filters,
      }

      return httpService
        .get<ApiCollectionResponse>(`/${resource}`, { params })
        .then((res) => res.data)
    },
    {
      ...queryOptions,
      onSuccess: (data) => {
        setTotalItems(data['hydra:totalItems'])
      },
    }
  )

  const [defaultData] = useState([])
  const listData = data?.['hydra:member'] || defaultData

  const hasCardsView = Boolean(cardComponent)

  /**
   * Rows selection
   */
  const selectRowHandler = useCallback(
    (id: number) => {
      if (id === -1) {
        const hasSelection = selected.length > 0
        const newSelected = hasSelection ? [] : listData.map((item) => item.id)
        onRowSelectionChange?.(newSelected, { rows: listData })
        return
      }
      const newSelected = [...selected]
      const selectedIndex = selected.indexOf(id)

      if (selectedIndex >= 0) {
        newSelected.splice(selectedIndex, 1)
      } else {
        newSelected.push(id)
      }

      const findRowData = listData.find((rowData) => rowData.id === id)

      onRowSelectionChange?.(newSelected, { id, rowData: findRowData })
    },
    [listData, onRowSelectionChange, selected]
  )

  const isAllSelected = listData.length > 0 && listData.length === selected.length

  return (
    <Box maxHeight={maxHeight} height={maxHeight} display="flex" flexDirection="column">
      <Grid
        container
        style={{ maxHeight: 'inherit', flex: 1, height: 'inherit' }}
        spacing={2}
        wrap="nowrap"
      >
        {leftSidebar && (
          <Grid item xs={2} className={classes.colum}>
            <Paper className={classes.paper}>{leftSidebar}</Paper>
          </Grid>
        )}
        <Grid item xs={rightSidebar ? 7 : 10} className={classes.colum}>
          <Paper className={classes.paper}>
            <Header
              hasCardView={hasCardsView}
              search={search}
              onSearchChange={searchHandler}
              actionsHolder={actionsHolder}
              isLoading={isFetching}
              breadcrumbs={breadcrumbs}
            />
            <Box height={`calc(100% - 56px - 52px - 55px)`}>
              <TableList
                columns={columns}
                data={listData}
                isLoading={isLoading}
                selected={selected}
                onRowSelect={selectRowHandler}
                onDoubleClickSelect={onDoubleClickSelect}
                isAllSelected={isAllSelected}
              />
            </Box>
            <Footer
              totalItems={totalItems}
              page={page}
              perPage={perPage}
              onPageChange={setPage}
              onPerPageChange={setPerPage}
            />
          </Paper>
        </Grid>
        {rightSidebar && (
          <Grid item xs={3} className={classes.colum}>
            <Paper className={classes.paper}>{rightSidebar}</Paper>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}
