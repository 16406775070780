import { DraggableLocation } from 'react-beautiful-dnd'
import { IEntityWidget } from './entity-widget'
import { WidgetType } from 'modules/new-entity/transformers/types'
import { SortedAttribute } from './attribute'
import { FormikProps } from 'formik'
import { WidgetDataRef, WidgetsDataRef } from './helpers'
import { RefObject } from 'react'
import { Section, SectionBackground } from 'core/types/layouts'

export type TChangeWidgetWidth = (
  optionKey: 'desktop_width' | 'tablet_width',
  widgetId: number,
  width: number
) => void

export type TChangeWidgetVisibility = (widgetId: number, value: boolean) => void

export type MoveWidgetFunc = (
  source: IEntityWidget[],
  destination: IEntityWidget[],
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation
) => void

type ActiveWidgets = {
  [container: string]: number[]
}

export type SetWidgetRefDataFunc = (
  id: number,
  formRef: FormikProps<any>,
  attributes: SortedAttribute[]
) => void

export type SetGlobalWidgetFunc = (widgetId: number, globalWidget: string) => void

export enum DndTypes {
  section = 'section',
  widget = 'widget',
}

export type PageBuilderContextProps = {
  widgets: IEntityWidget[]
  sections: Section[]
  dynamicLayout: boolean
  pageBuilderLoading: boolean
  activeWidgets: ActiveWidgets
  widgetsDataRef: RefObject<WidgetsDataRef>
  actions: {
    addWidget: (container: string, widgetType: WidgetType, type: 'push' | 'unshift') => void
    changeWidgetWidth: TChangeWidgetWidth
    changeWidgetVisibility: TChangeWidgetVisibility
    reorderWidgets: (containerId: string, startIndex: number, endIndex: number) => void
    moveWidget: MoveWidgetFunc
    moveWidgetUp: (widgetId: number) => void
    moveWidgetDown: (widgetId: number) => void
    removeWidget: (widgetId: number) => void
    duplicateWidget: (widgetId: number) => void
    toggleContainer: (container: string, widgets: IEntityWidget[]) => void
    toggleWidget: (container: string, widget: number) => void
    isWidgetExpanded: (container: string, widget: number) => boolean
    setWidgetRefData: SetWidgetRefDataFunc
    setGlobalWidget: SetGlobalWidgetFunc
    getWidgetRefData: (widgetId: number) => WidgetDataRef | null
    toggleDynamicLayout: () => void
    addSection: (index?: number) => void
    removeSection: ({ sectionId, containerId }: { sectionId: string; containerId: string }) => void
    reorderSection: (dropIndex: number, destIndex: number) => void
    selectSectiontBg: (sectionId: string, colorName: SectionBackground) => void
  }
}
