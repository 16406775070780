import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { defaultThemeColors } from 'core/themes/default/default'

const pageBuilderStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      paddingBottom: '50px',
      marginTop: 16,
    },
    switcherWrap: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: '10px',
    },
    buttonControl: {
      padding: '3px 3px',
      minWidth: '45px',
      backgroundColor: defaultThemeColors.purpleLight,
    },
    addSection: {
      display: 'flex',
      justifyContent: 'center',
      margin: '0 auto 15px',
      opacity: 1,
    },
    hidden: {
      opacity: 0,
      transition: theme.transitions.create(['opacity'], {
        duration: 50,
      }),
    },
  })
)

export default pageBuilderStyles
