import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { defaultThemeSizes } from 'core/themes/default/default'

const { rightSidebarWidth } = defaultThemeSizes

const styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    main: {
      display: 'flex',
    },
    wrapper: {
      flex: 1,
      padding: theme.spacing(0, 4),
      background: theme.palette.background.paper,
    },
    content: {
      padding: theme.spacing(2, 0),
    },
    aside: {
      width: rightSidebarWidth,
      '& > div': {
        width: rightSidebarWidth,
        overflowX: 'hidden',
        padding: theme.spacing(2),
      },
    },
  })
)

export default styles
