// Core
import React, { FC } from 'react'
import { DialogTitle as MuiDialogTitle, IconButton, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
// Styles
import useStyles from './dialog-title.styles'

type Props = {
  onClose?: any
}

const DialogTitle: FC<Props> = (props) => {
  const classes = useStyles()
  const { children, onClose } = props

  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography component="h2" variant="h6">
        {children}
      </Typography>
      {onClose && (
        <IconButton onClick={() => onClose()} size="small">
          <Close />
        </IconButton>
      )}
    </MuiDialogTitle>
  )
}

export default DialogTitle
