import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import { Folder } from '@material-ui/icons'
import { useGetMediaFolders } from 'modules/media/hooks/use-get-media-folders'
import React, { FC, useCallback, useMemo, useState } from 'react'
import { DialogTitle } from 'ui'
import { Breadcrumbs } from '../breadcrumbs'

type Props = {
  title: string
  mainActionTitle: string
  selectedFolder: number
  onMainActionClick: (folderId: number) => void
} & Pick<DialogProps, 'open' | 'onClose'>

export const FolderSelect: FC<Props> = ({
  title,
  mainActionTitle,
  onMainActionClick,
  selectedFolder: selectedFolderProp,
  ...dialogProps
}) => {
  const { foldersTreeData, foldersData } = useGetMediaFolders()

  const [selectedFolder, setSelectedFolder] = useState<number | null>()
  const [folderFilter, setFolderFilter] = useState<number | null>(selectedFolderProp)

  const foldersList = useMemo(() => {
    if (!foldersData) return []

    return foldersData.filter((folder) => {
      if (!folderFilter) {
        return !folder.parent
      }

      return folder.parent && folder.parent === `/api/media_folders/${folderFilter}`
    })
  }, [folderFilter, foldersData])

  const selectedFolderData = useMemo(() => {
    const findFolder = foldersData?.find((folder) => folder.id === selectedFolder)

    return findFolder
  }, [foldersData, selectedFolder])

  if (!foldersTreeData || !foldersData) return null

  return (
    <Dialog {...dialogProps} fullWidth maxWidth="sm">
      <DialogTitle onClose={dialogProps.onClose}>
        {title} {selectedFolderData?.name}
      </DialogTitle>
      <DialogContent>
        <Breadcrumbs
          foldersData={foldersData}
          folder={folderFilter!}
          onSelect={(id) => setFolderFilter(id)}
        />
        <List>
          {foldersList?.map((folder) => (
            <ListItem
              button
              key={folder.id}
              selected={selectedFolder === folder.id}
              onClick={() => setSelectedFolder(folder.id)}
            >
              <ListItemIcon>
                <Folder />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Link
                    display="inline"
                    color="textPrimary"
                    underline="hover"
                    onClick={(e) => {
                      e.stopPropagation()
                      setFolderFilter(folder.id)
                    }}
                  >
                    {folder.name}
                  </Link>
                }
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => dialogProps.onClose?.({}, 'backdropClick')}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onMainActionClick(selectedFolder!)}
          disabled={!selectedFolder}
        >
          {mainActionTitle}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

type FolderSelectState = {
  title: string
  mainActionTitle: string
  onSelectFolder: () => void
  onMainAction: (folderId: number) => void
  selectedFolder: number
}

export const useFolderSelect = () => {
  const [state, setState] = useState<FolderSelectState | null>(null)

  const isOpen = Boolean(state)

  const FolderSelectModal = useMemo(() => {
    if (!state) return null

    return (
      <FolderSelect
        title={state?.title}
        open={isOpen}
        mainActionTitle={state.mainActionTitle}
        onMainActionClick={state.onMainAction}
        selectedFolder={state.selectedFolder}
        onClose={() => setState(null)}
      />
    )
  }, [isOpen, state])

  const openFolderSelect = useCallback((state: FolderSelectState) => {
    setState(state)
  }, [])

  const closeFolderSelect = useCallback(() => {
    setState(null)
  }, [])

  return {
    FolderSelectModal,
    openFolderSelect,
    closeFolderSelect,
  }
}
