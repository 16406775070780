import { useCallback, useRef } from 'react'
import { MediaListItem } from '../types'
import { useMutation } from 'react-query'
import { httpService } from 'core/data'
import { useConfirmation } from 'core/confirmation'
import { useNotify } from 'core/hooks'
import { useFolderSelect } from '../components/folder-select'

export type HelperStorage = Record<number, MediaListItem>

export type MutationPayload = {
  folderId?: number
  action: 'delete' | 'copy' | 'move'
}

type Params = {
  selected: number[]
  helperStorage: HelperStorage
  selectedFolder: number
  onSuccess?: (action: MutationPayload['action']) => void
}

export const useMediaBulkActions = ({
  selected,
  helperStorage,
  selectedFolder,
  onSuccess,
}: Params) => {
  const { setConfirmation } = useConfirmation()
  const notify = useNotify()

  const { FolderSelectModal, openFolderSelect, closeFolderSelect } = useFolderSelect()

  const mutation = useMutation(
    (payload: MutationPayload) => {
      const { action, folderId } = payload
      const items = generateBulkIriCollection(selected, helperStorage)
      const destination = `/api/media_folders/${folderId}`

      if (action === 'copy') {
        return httpService.post('/media_advanceds/copying', { items, destination })
      }

      return httpService.post('/media_advanceds/deletion', { items })
    },
    {
      onSuccess: (_, payload) => {
        onSuccess?.(payload.action)
        notify('Success', { type: 'success' })
        closeFolderSelect()
      },
      onError: () => {
        notify('Something went wrong', { type: 'error' })
      },
    }
  )

  const bulkDelete = useCallback(() => {
    setConfirmation({
      open: true,
      title: 'Delete confirmation',
      content: 'Are you sure want to delete this items?',
      onSuccess: () => mutation.mutate({ action: 'delete' }),
    })
  }, [mutation, setConfirmation])

  const bulkCopy = useCallback(() => {
    openFolderSelect({
      title: `Copy ${selected.length} items to`,
      mainActionTitle: 'Copy here',
      selectedFolder,
      onSelectFolder: () => {
        console.log('select folder')
      },
      onMainAction: (folderId) => {
        setConfirmation({
          open: true,
          title: 'Copy confirmation',
          content: 'Are you sure want to copy this items?',
          onSuccess: () => mutation.mutate({ action: 'copy', folderId }),
        })
      },
    })
  }, [mutation, openFolderSelect, selected.length, selectedFolder, setConfirmation])

  const bulkMove = useCallback(() => {
    setConfirmation({
      open: true,
      title: 'Move confirmation',
      content: 'Are you sure want to move this items?',
      onSuccess: () => mutation.mutate({ action: 'move' }),
    })
  }, [mutation, setConfirmation])

  return {
    bulkDelete,
    bulkCopy,
    bulkMove,
    FolderSelectModal,
  }
}

function generateBulkIriCollection(selected: number[], helperStorage: HelperStorage) {
  return selected.map((id) => {
    const sourceItem = helperStorage[id]

    if (!sourceItem) throw new Error('Error in generation bulk iris')

    const isFolder = sourceItem.type === 'folder'
    const resource = isFolder ? 'media_folders' : 'media'

    return `/api/${resource}/${id}`
  })
}

type SetHelperStorageDataParams = {
  selected: number[]
  rowData?: MediaListItem
  rows?: MediaListItem[]
}

export const useMediaBulkActionsHelperStorage = () => {
  const helperStorage = useRef<HelperStorage>({})

  const setHelperStorageData = useCallback((params: SetHelperStorageDataParams) => {
    const { selected, rowData, rows = [] } = params

    const prevStoredData = Object.keys(helperStorage.current).map(
      (id) => helperStorage.current[+id]
    )

    const combinedSourceData = rowData ? [rowData, ...rows, ...prevStoredData] : rows

    helperStorage.current = selected.reduce<HelperStorage>((acc, id) => {
      const findSourceItem = combinedSourceData.find((item) => item.id === id)

      if (findSourceItem) {
        acc[id] = findSourceItem
      }

      return acc
    }, {})
  }, [])

  return {
    helperStorage: helperStorage.current,
    setHelperStorageData,
  }
}
