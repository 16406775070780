import React, { FC, useMemo } from 'react'
import {
  Checkbox,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { GridColumn } from '../../types'
import { RelationCell } from './relation-cell'
import { DateCell } from './date-cell'
import RowsSkeleton from 'modules/new-entity/components/data-view/rows-skeleton'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  rowBase: {
    '&:hover .select-row-checkbox:not(.Mui-checked)': {
      opacity: '1',
    },
    '& .select-row-checkbox:not(.Mui-checked)': {
      opacity: 0,
    },
  },
  row: {
    cursor: 'pointer',
    '&.MuiTableRow-hover:hover': {
      backgroundColor: '#f5f5f5',
    },
    '&.Mui-selected': {
      backgroundColor: '#e7e5ff',
    },
  },
}))

type Props = {
  columns: GridColumn[]
  data: Record<string, any>[]
  isLoading: boolean
  selected: number[]
  isAllSelected: boolean
  onRowSelect?: (id: number) => void
  onDoubleClickSelect?: (value: any) => void
}

export const TableList: FC<Props> = ({
  columns,
  data,
  isLoading,
  selected,
  isAllSelected,
  onRowSelect,
  onDoubleClickSelect,
}) => {
  const classes = useStyles()

  return (
    <TableContainer style={{ maxHeight: '100%' }}>
      <Table stickyHeader size="small">
        <TableHeader
          columns={columns}
          onRowSelect={onRowSelect}
          selected={selected}
          isAllSelected={isAllSelected}
        />
        <TableBody>
          {isLoading && <RowsSkeleton columnsCount={columns.length + 1} show />}
          {data.map((rowData) => (
            <TableRow
              key={rowData.id}
              selected={selected.includes(rowData.id)}
              onClick={() => onRowSelect?.(rowData.id)}
              onDoubleClick={() => onDoubleClickSelect?.(rowData)}
              hover={Boolean(onRowSelect)}
              className={clsx(classes.rowBase, classes.row)}
            >
              <TableCell padding="checkbox">
                <Checkbox
                  className="select-row-checkbox"
                  color="primary"
                  checked={selected.includes(rowData.id)}
                />
              </TableCell>
              {columns.map((column) => (
                <TableCellRenderer key={column.field} column={column} rowData={rowData} />
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

type TableHeaderProps = {
  columns: GridColumn[]
  selected: number[]
  isAllSelected: boolean
  onRowSelect?: (id: number) => void
}

const TableHeader: FC<TableHeaderProps> = ({ columns, onRowSelect, selected, isAllSelected }) => {
  const classes = useStyles()

  return (
    <TableHead>
      <TableRow className={classes.rowBase}>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={!isAllSelected && selected.length > 0}
            color="primary"
            onChange={() => onRowSelect?.(-1)}
            checked={selected.length > 0}
            className="select-row-checkbox"
          />
        </TableCell>
        {columns.map((column) => (
          <TableCell style={{ textTransform: 'capitalize' }} key={column.field}>
            {column.title ?? column.field}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

type TableCellRendererProps = {
  column: GridColumn
  rowData: Record<string, any>
}

const TableCellRenderer: FC<TableCellRendererProps> = ({ column, rowData }) => {
  const { type } = column

  const content = useMemo(() => {
    if (type === 'custom') {
      return column.cellRenderer({ rowData })
    }
    if (type === 'relation') {
      return <RelationCell column={column} rowData={rowData} />
    }
    if (type === 'date') {
      return <DateCell column={column} rowData={rowData} />
    }

    return rowData[column.field]
  }, [column, rowData, type])

  return <TableCell>{content}</TableCell>
}
