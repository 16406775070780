import React, { FC } from 'react'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'

type Props = {
  onRemove?: () => void
  onCopy?: () => void
  onMove?: () => void
}

export const Actions: FC<Props> = ({ onRemove, onCopy, onMove }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton size="small" onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
        {onRemove && (
          <MenuItem
            button
            onClick={() => {
              handleClose()
              onRemove?.()
            }}
          >
            Delete
          </MenuItem>
        )}
        {onCopy && (
          <MenuItem
            button
            onClick={() => {
              handleClose()
              onCopy?.()
            }}
          >
            Copy
          </MenuItem>
        )}
        {onMove && (
          <MenuItem
            button
            onClick={() => {
              handleClose()
              onMove?.()
            }}
          >
            Move
          </MenuItem>
        )}
      </Menu>
    </>
  )
}
