// Core
import React, { FC, useCallback } from 'react'
import { Attachment } from 'ui'
// Hooks
import useFieldController from 'core/form/hooks/use-field-controller'
import { useSelectMedia } from 'modules/media/select-media'
// Utils
import { getIdFromIri } from 'core/utils'
// Types
import { Media } from 'modules/media/types'

type TProps = {
  label?: string
  name: string
  type: string
  disabled?: boolean
  slug: string
  required?: boolean
}

const ImageAttachment: FC<TProps> = (props) => {
  const { name, label, type, disabled, slug, required } = props
  const [{ value }, meta, { setValue, setTouched }] = useFieldController(name)

  const selectHandler = useCallback(
    (media: Media) => {
      setTouched(true)
      setValue(media['@id'])
    },
    [setTouched, setValue]
  )

  const { MediaManagerModal, openSelectMedia } = useSelectMedia()

  return (
    <>
      <Attachment
        type={type}
        openPopup={() => openSelectMedia({ handler: selectHandler })}
        value={value}
        error={meta.errorMsg}
        touched={meta.touched}
        label={label}
        setValue={setValue}
        disabled={disabled}
        slug={slug}
        required={required}
        onEdit={() =>
          openSelectMedia({ handler: selectHandler, selectedMedia: +getIdFromIri(value) })
        }
      />
      {MediaManagerModal}
    </>
  )
}

export default ImageAttachment
