import React, { FC } from 'react'
import {
  Box,
  Button,
  Divider,
  IconButton,
  makeStyles,
  Paper,
  Slide,
  Typography,
} from '@material-ui/core'
import { Close, Delete, ExitToApp, FileCopy } from '@material-ui/icons'
import { Media } from 'modules/media/types'
import { HelperStorage } from 'modules/media/media-manager/use-media-bulk-actions'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: '10vh',
    right: 0,
    left: 0,
    margin: '0 auto',
    width: 'fit-content',
  },
  counter: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  divider: {
    margin: theme.spacing(0, 2),
  },
}))

type Props = {
  selected: number[]
  onClose: () => void
  onCopy: () => void
  onMove: () => void
  onDelete: () => void
  selectedMedia?: number | null
  onInsert?: (id: Media) => void
  helperStorage: HelperStorage
}

export const BulkToolbar: FC<Props> = ({
  selected,
  onClose,
  onCopy,
  onMove,
  onDelete,
  selectedMedia,
  onInsert,
  helperStorage,
}) => {
  const selectedCount = selected.length
  const isOpen = selectedCount > 0
  const classes = useStyles()

  const enabledInsert = Boolean(onInsert) && Boolean(selectedMedia) && selectedCount === 1

  const insertHandler = () => {
    if (!enabledInsert || !selectedMedia) return
    const sourceItem = helperStorage[selectedMedia]
    // @ts-ignore
    onInsert?.(sourceItem)
  }

  return (
    <Slide direction="up" in={isOpen} unmountOnExit>
      <Paper className={classes.root}>
        <Box display="flex" alignItems="center" p={1}>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
          <Typography className={classes.counter}>{selectedCount} selected</Typography>
          <Divider orientation="vertical" flexItem className={classes.divider} />
          <Box mr={2}>
            <Button onClick={onCopy} startIcon={<FileCopy />}>
              Copy
            </Button>
            <Button disabled onClick={onMove} startIcon={<ExitToApp />}>
              Move
            </Button>
            <Button onClick={onDelete} startIcon={<Delete />}>
              Delete
            </Button>
          </Box>
          <Button
            variant="contained"
            color="primary"
            disabled={!enabledInsert}
            onClick={insertHandler}
          >
            Insert
          </Button>
        </Box>
      </Paper>
    </Slide>
  )
}
