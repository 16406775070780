import React, { FC } from 'react'
import { Box, Button, Divider } from '@material-ui/core'
import { FileForm } from './file-form'
import { Media } from '../../types'
import { SidebarHeader } from '../sidebar-header'
import { FileDetails } from './file-details'
import { Actions } from '../actions'
import { useGetOne } from 'core/data/v2/hooks/use-get-one'
import { useDeleteOne } from 'core/data/v2/hooks/use-delete-one'
import { MutationButton } from '../mutation-button'
import { useMutateOne } from 'core/data/v2/hooks/use-mutate-one'
import { SidebarPanelLoading } from '../sidebar-panel-loading'

type Props = {
  mediaId: number
  onClose: () => void
  onDeleteSuccess: () => void
  onUpdateSuccess: () => void
  onSelectMedia?: (media: Media) => void
}

const FILE_FORM_ID = 'edit-file-form'

export const FilePreview: FC<Props> = ({
  mediaId,
  onClose,
  onDeleteSuccess,
  onUpdateSuccess,
  onSelectMedia,
}) => {
  const { data, isLoading } = useGetOne<Media>('media', mediaId)

  const { mutate, isLoading: mutationLoading } = useMutateOne('media', {
    onSuccess: onUpdateSuccess,
  })

  const deleteM = useDeleteOne('media', {
    onSuccess: onDeleteSuccess,
  })

  if (isLoading || !data) return <SidebarPanelLoading />

  return (
    <Box height="inherit" display="flex" flexDirection="column">
      <SidebarHeader
        title={data.name}
        onClose={onClose}
        actions={<Actions onRemove={() => deleteM.mutate(data.id)} />}
      />
      <Box maxHeight="100%" overflow="auto" px={2}>
        <Box mt={2}>
          <FileDetails data={data} />
        </Box>
        <Box mt={2}>
          <FileForm fileData={data} formId={FILE_FORM_ID} onSubmit={mutate} key={data.id} />
        </Box>
      </Box>
      <Box marginTop="auto">
        <Divider />
        <Box p={2}>
          <Box display="flex" style={{ gap: 16 }} justifyContent="flex-end">
            <MutationButton form={FILE_FORM_ID} type="submit" isLoading={mutationLoading}>
              Save
            </MutationButton>
            {onSelectMedia && (
              <Button variant="contained" color="primary" onClick={() => onSelectMedia(data)}>
                Insert
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
