// Core
import React, { FC } from 'react'
import { Icon, IconButton, ListItemIcon, MenuItem, Popover } from '@material-ui/core'
// Types
import { Actions } from 'core/data/types/actions'

type Props = {
  actions: Actions
  anchorEl: HTMLDivElement | null
  showActions: boolean
  onShowActions: (value: boolean) => void
  loading: boolean
}

const ActionsPopover: FC<Props> = (props) => {
  const { actions, anchorEl, showActions, onShowActions, loading } = props

  return (
    <>
      <IconButton
        size="small"
        disabled={loading}
        style={{ borderRadius: 3, marginLeft: 5 }}
        onClick={() => onShowActions(true)}
        data-aqa-action="open_actions"
      >
        <Icon className="icon-more_horiz-1" />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={showActions}
        onClose={() => onShowActions(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {actions.map((action) => {
          if (!action.condition) return null

          const iconEl = !action.icon ? null : typeof action.icon === 'string' ? (
            <Icon className={action.icon} />
          ) : (
            action.icon
          )

          return (
            <MenuItem
              key={action.name}
              data-aqa-action={action.name}
              onClick={() => action.action(onShowActions)}
            >
              <ListItemIcon>{iconEl}</ListItemIcon>
              {action.label}
            </MenuItem>
          )
        })}
      </Popover>
    </>
  )
}

export default ActionsPopover
